<template>
  <div class="container">
    <b-overlay :show="isLoading">
      <vue-pdf-embed
        :source="loadPdf()"
        @rendered="isLoading = false"
      />
    </b-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import commercialTermsEn from '@/assets/pdf/DIVEMA-conditions-commerciales_en.pdf'
import commercialTermsFr from '@/assets/pdf/DIVEMA-conditions-commerciales_fr.pdf'

export default defineComponent({
  name: 'CommercialTerms',

  components: {
    VuePdfEmbed,
  },

  setup(_props, ctx) {
    const { $store } = ctx.root

    const commercialTerms = {
      en: commercialTermsEn,
      fr: commercialTermsFr,
    }

    const isLoading = ref(true)

    const loadPdf = () => commercialTerms[$store.state.appConfig.lang] || commercialTerms.fr

    return {
      loadPdf,
      isLoading,
    }
  },
})
</script>
